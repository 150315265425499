<script setup lang="ts">
interface Props {
  contrastBackground?: boolean
}

withDefaults(defineProps<Props>(), {
  contrastBackground: false,
})

const trafficData = [
  {
    name: 'Геосервисы (карты)',
    value: '38,6%',
  },
  {
    name: 'Органический трафик',
    value: '30,3%',
  },
  {
    name: 'Платный трафик',
    value: '17,5%',
  },
  {
    name: 'Прямые переходы',
    value: '3,9%',
  },
  {
    name: 'Социальные сети',
    value: '0,9%',
  },
]
</script>

<template>
  <section>
    <div :class="contrastBackground ? 'bg-milky-200' : ''" class="w-full">
      <div class="max-w-[1012px] w-full px-4 mx-auto md:py-28 py-12 flex flex-row gap-10">
        <div class="flex flex-col-reverse md:flex-row items-center gap-8 md:gap-12">
          <div class="md:bg-milky-200 bg-transparent p-0 md:px-12 md:pb-16 md:pt-12 rounded-md ">
            <h5 class="text-m-primary-large md:text-h5 font-semibold mb-6">
              Доли уникальных обращений по каналам трафика
            </h5>
            <div class=" flex flex-col gap-3">
              <div v-for="item in trafficData" class="font-thin px-2 py-1 rounded-lg first:bg-yellow-500 flex flex-row justify-between text-primary md:text-primary-large">
                <span>{{ item.name }}</span>
                <span>{{ item.value }}</span>
              </div>
              <p class="px-2 text-m-primary-small md:text-primary-small text-gray-500">
                Период: январь — декабрь, 2022
              </p>
            </div>
          </div>
          <div class="max-w-[466px]">
            <div class="flex flex-col gap-4">
              <h2 class="md:text-h2 text-m-h2">
                38% клиентов уже приходит из карт
              </h2>
              <!--                TODO: add link -->
              <p class="text-m-primary md:text-primary">
                Источник — исследование Calltouch. В выборку попали данные 290+ компаний и более 5,4 млн уникальных обращений: звонков, заявок и обратных звонков.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">

</style>
